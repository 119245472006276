// @flow
import { type JSSThemeDefaultBetgeniusType } from '../JSSThemeDefaultBetgenius';

export const JSSThemeBetgenius60010: JSSThemeDefaultBetgeniusType = (
  themeVars
) => {
  return {
    liveBetbuilderWrapper: {
      margin: 10,

      '@media (max-width: 660px)': {
        margin: 10,
      },
    },
  };
};
