import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60010: JSSThemeDefaultAccountSectionType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { text1, text5 },
    },
  } = themeVars;
  return {
    AccountPageDesktop: {
      accountPageLeftContent: {
        background: text1,
        width: 300,
      },
    },
    BetsHistory: {
      filterSelectionItem: {
        '&:hover': {
          background: text5,
        },
      },
    },
  };
};
