// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets60010: JSSThemeDefaultMyBetsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteBg1, text1White, whitePrimary },
      colorTypes: { background3, text1 },
    },
  } = themeVars;

  return {
    ConfirmationBox: {
      text: {
        color: text1,
        fontWeight: 'normal',
      },
      confirmation: {
        background: background3,
      },
      loading: whiteBg1,
    },

    MyBets: {
      content: {
        ...whiteBg1,
      },
      myBetsTabsItem: {
        ...text1White,
        '&:hover, &.active': whitePrimary,
      },
    },
  };
};
