// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { getButtonColors } from '../jss-helpers';
import { JSSThemeOutcomes60010 } from './JSSThemeOutcomes60010';
import { JSSThemeSportsMenu60010 } from './JSSThemeSportsMenu60010';
import { JSSThemeHeaderMobile60010 } from './JSSThemeHeaderMobile60010';
import { JSSThemeHeader60010 } from './JSSThemeHeader60010';
import { JSSThemeHeaderMenu60010 } from './JSSThemeHeaderMenu60010';
import { JSSThemeCoupons60010 } from './JSSThemeCoupons60010';
import { JSSThemeForm60010 } from './JSSThemeForm60010';
import { JSSThemeAuthForm60010 } from './JSSThemeAuthForm60010';
import { JSSThemeBalance60010 } from './JSSThemeBalance60010';
import { JSSThemeBonuses60010 } from './JSSThemeBonuses60010';
import { JSSThemeBetslip60010 } from './JSSThemeBetslip60010';
import { JSSThemePromotions60010 } from './JSSThemePromotions60010';
import { JSSThemeSitemap60010 } from './JSSThemeSitemap60010';
import { JSSThemeMyBets60010 } from './JSSThemeMyBets60010';
import { JSSThemeCasino60010 } from './JSSThemeCasino60010';
import { JSSThemeBannerItems60010 } from './JSSThemeBannerItems60010';
import { JSSThemeSearch60010 } from './JSSThemeSearch60010';
import { JSSThemePopularLeagues60010 } from './JSSThemePopularLeagues60010';
import { JSSThemeAccountSection60010 } from './JSSThemeAccountSection60010';
import { JSSThemeLive60010 } from './JSSThemeLive60010';
import { JSSThemeOryx60010 } from './JSSThemeOryx60010';
import { JSSThemeBetgenius60010 } from './JSSThemeBetgenius60010';

export const colorTypes60010: ColorTypes = {
  ...colorTypes,
  primary: '#17A34A',
  text1: '#1a1a1a',
  text2: '#4E5A6C',
  text3: '',
  text4: '#377440', //available for a new color
  text5: '#188b43', //available for a new color
  background1: '#0D4B24',
  background2: '#C1D0C8',
  background3: '#ECECEC',
  background4: '', //available for a new color
  background5: '', //available for a new color
  background6: '', //available for a new color
  shadow: 'rgba(0, 0, 0, 0.3)', //black whith opacity for text shadow
  accent: '',
  success: '#21a22d',
  error: '#FF5C5C',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: '#fece38',
  inform: '#fece38',
  neutral1: '#f3f3f3',
  neutral2: '',
  neutral3: '',
  neutral4: '', //available for a new color
  border1: '#eee',
  border2: '#c6c6c6',
};

const pick = ({
  text2Primary,
  blackBg3,
  whiteText1,
  whitePrimary,
  whiteBg1,
  text1White,
  text1Bg2,
  whiteBg2,
  whiteError,
  text1Bg3,
}) => ({
  text2Primary,
  blackBg3,
  whiteText1,
  whitePrimary,
  whiteBg1,
  text1White,
  text1Bg2,
  whiteBg2,
  whiteError,
  text1Bg3,
});

export const colorCombinations60010 = getColorCombinations(
  colorTypes60010,
  pick
);

export const JSSThemeVariables60010: JSSThemeVariablesShapeType = {
  colors: {
    colorCombinations: colorCombinations60010,
    colorTypes: colorTypes60010,
  },
  fontSizes: {
    xxxl: 36,
  },
};

export const JSSTheme60010: JSSThemeShapeType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorCombinations: { whiteAccent, whiteBg1, whitePrimary },
      colorTypes: { white, primary, background1, background2, text1 },
    },
  } = themeVars;
  return {
    Heading: {
      common: {
        background: background1,
        color: white,
      },
    },
    Button: {
      default: getButtonColors(whiteAccent),
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whiteBg1),
      accent: getButtonColors(whiteAccent),
      linkAccent: {
        color: white,
        opacity: 0.7,
        '&:hover': {
          opacity: 1,
          color: white,
        },
      },
      large: {
        fontSize: fontSizes.xxl,
        padding: [margins.md, margins.xm],
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: primary,
      },
    },
    header: JSSThemeHeader60010(themeVars),
    headerMenu: JSSThemeHeaderMenu60010(themeVars),
    outcomes: JSSThemeOutcomes60010(themeVars),
    sportsMenu: JSSThemeSportsMenu60010(themeVars),
    headerMobile: JSSThemeHeaderMobile60010(themeVars),
    coupons: JSSThemeCoupons60010(themeVars),
    form: JSSThemeForm60010(themeVars),
    authForm: JSSThemeAuthForm60010(themeVars),
    balance: JSSThemeBalance60010(themeVars),
    bonuses: JSSThemeBonuses60010(themeVars),
    betslip: JSSThemeBetslip60010(themeVars),
    mybets: JSSThemeMyBets60010(themeVars),
    casino: JSSThemeCasino60010(themeVars),
    popularLeagues: JSSThemePopularLeagues60010(themeVars),
    banners: JSSThemeBannerItems60010(themeVars),
    accountSection: JSSThemeAccountSection60010(themeVars),
    promotions: JSSThemePromotions60010(themeVars),
    sitemap: JSSThemeSitemap60010(themeVars),
    oryx: JSSThemeOryx60010(themeVars),
    betgenius: JSSThemeBetgenius60010(themeVars),
    BetslipMyBetsPanelTitle: {
      heading: {
        ...whitePrimary,
      },
    },
    BetslipMyBetsPanelUI: {
      singleSelectionWrapper: {
        borderTop: `1px solid ${white}`,
      },
      panel: {
        background: white,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        color: text1,
        borderTop: `1px solid ${background2}`,
      },
      stakePotential: {
        color: text1,
      },
    },
    NoBetsMessage: {
      root: { ...whiteBg1, fontWeight: 'normal' },
    },
    LiveCalendar: {
      common: {
        margin: 0,
        padding: margins.md,
      },
      h2: {
        color: text1,
        background: 'transparent',
        fontSize: fontSizes.lg,
      },
      dateTitle: {
        fontSize: fontSizes.md,
        color: text1,
        background: 'transparent',
      },
      dateEvents: {
        margin: 0,
      },
      dateEvent: {
        // borderBottom: `1px solid ${border1}`,
        color: text1,
        background: 'transparent',
        '&:hover': {
          color: text1,
          background: background2,
        },
      },
      link: {
        color: text1,
      },
      dateEventNoMarkets: {
        '&:hover': {
          cursor: 'default',
        },
      },
      dateEventIcon: {},
      icon: {
        fontSize: fontSizes.lg,
        marginRight: margins.md,
      },
    },
    search: JSSThemeSearch60010(themeVars),
    live: JSSThemeLive60010(themeVars),
  };
};

export const getJSSThemePatterns60010: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { whitePrimary, whiteAccent },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whitePrimary),
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '38px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
