// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60010,
  JSSThemeVariables60010,
  getJSSThemePatterns60010,
} from './JSSTheme60010';
import { GeoblockPage60010 } from './GeoblockPage60010';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop60010').then((module) => module.FullDesktop60010),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile60010').then((module) => module.FullMobile60010),
  }),
  JSSTheme: JSSTheme60010,
  JSSThemeVariables: JSSThemeVariables60010,
  JSSThemePatterns: getJSSThemePatterns60010,
  GeoblockPage: GeoblockPage60010,
});
