import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu60010: JSSThemeDefaultSportsMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { whitePrimary, whiteText1 },
      colorTypes: { text1, white, primary },
    },
    margins,
  } = themeVars;

  return {
    SportsMenuDesktop: {
      sport: {
        ...whiteText1,
      },
      region: {
        ...whiteText1,
      },
    },
    SubMenuListUIMobile: {
      link: {
        color: white,
        '&:hover,&:focus': {
          color: primary,
        },
        '&.active': {
          ...whitePrimary,
        },
      },
    },
    HorizontalSportsListUISmallIcons: {
      item: {
        color: text1,
        borderRight: `1px solid ${text1}`,
        padding: [0, margins.md],
      },
      sportLink: {
        padding: [margins.xs, 0],
        '&.active, &:hover': {
          color: primary,
        },
      },
    },

    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: primary,
        },
      },
      sportName: {
        color: white,
        '&.active': {
          color: primary,
        },
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        background: white,
        color: primary,
      },
      title: {
        color: white,
      },
    },
  };
};
