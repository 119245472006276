// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader60010: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    colors: {
      colorTypes: { primary },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: primary,
      },
      authContentWrap: {
        justifyContent: 'center',
      },
      authWrapper: {
        padding: 0,
      },
    },
    LogoBrand: {
      logo: {
        // maxWidth: 200,
      },
      logoWrapper: {
        '@media screen and (max-width: 1310px)': {
          width: 200,
        },
        '@media screen and (max-width: 1100px)': {
          width: 160,
        },
      },
    },
  };
};
