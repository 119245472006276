// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems60010: JSSThemeDefaultBannerItemsType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    fonts,
    colors: {
      colorTypes: { primary, white, background2, background1, black },
    },
  } = themeVars;
  return {
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        maxHeight: 271,
        margin: 0,
        marginRight: 0,
        padding: margins.md,
      },
      contentWrapper: {
        backgroundColor: white,
        padding: 0,
        width: 'auto',
        borderRadius: '0 0 5px 5px',
      },
      imgContainer: {
        height: '135px',
        backgroundColor: primary,
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
        borderRadius: '5px 5px 0 0',
      },
    },
    SideBanner: {
      containerWithContent: {
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
        backgroundSize: '100%',
        backgroundPosition: 'center top',
        '&::before': {
          width: '100%',
          background:
            'linear-gradient(180deg, rgba(15,55,43,1) 0%, rgba(15,55,43,0) 100%)',
          height: 185,
          top: '0',
          display: 'block',
          content: '""',
          position: 'absolute',
        },
      },
    },
    BannerContent: {
      bannerContentRoot: {
        height: '100%',
        justifyContent: 'space-between',
      },
      bannerContentSportsbookSide: {
        padding: [margins.lg, margins.xm],
      },
      bannerContentCasinoCarousel: {
        justifyContent: 'flex-start',
        padding: [margins.xl, margins.lg, margins.lg],
      },
      bannerContentCasinoLiveCarousel: {
        justifyContent: 'flex-start',
        padding: [margins.xl, margins.lg, margins.lg],
      },
      bannerContentCasinoCarouselMobile: {
        padding: [margins.lg],
      },
      bannerContentCasinoLiveCarouselMobile: {
        padding: [margins.lg],
      },
      descriptionMobile: {
        fontSize: fontSizes.md,
        fontWeight: 'normal',
        padding: [margins.md, 0, 0],
      },
      headerCasinoCarousel: {
        fontSize: '75px',
        lineHeight: '72px',
        fontWeight: 900,
      },
      headerCasinoLiveCarousel: {
        fontSize: '75px',
        lineHeight: '72px',
        fontWeight: 900,
      },
      descriptionCasinoCarousel: {
        fontSize: '25px',
        lineHeight: 'auto',
        fontWeight: 900,
        padding: [margins.xm, 0, margins.xs],
      },
      descriptionCasinoLiveCarousel: {
        fontSize: '25px',
        lineHeight: 'auto',
        fontWeight: 900,
        padding: [margins.xm, 0, margins.xs],
      },
      textWrapperPromo: {
        background: 'transparent',
      },
      headerPromo: {
        padding: [margins.md, 0, 0],
        textShadow: 'none',
        fontWeight: 'bold',
        lineHeight: '1.2',
        fontSize: 26,
        color: white,
      },
      descriptionPromo: {
        fontSize: fontSizes.md,
        fontWeight: 'normal',
        color: white,
        padding: [margins.md, margins.xs],
      },
      textWrapperFill: {
        padding: 0,
        zIndex: 1,
        maxWidth: 900,
      },
      headerSportsbookSide: {
        textShadow: 'none',
        fontSize: 30,
        fontWeight: '900',
        lineHeight: 1.2,
      },
      descriptionSportsbookSide: {
        padding: '5px 0',
        textShadow: 'none',
        fontSize: fontSizes.md,
        maxWidth: '100%',
        fontWeight: 'normal',
      },
      buttonsWrapperRoot: {
        margin: [margins.md, 0, 0],
        zIndex: 1,
      },
      headerSportsbookCarousel: {
        fontSize: 25,
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: '900',
        color: black,
        fontFamily: fonts.agora,
        marginRight: margins.md,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      },
      descriptionSportsbookCarousel: {
        fontSize: fontSizes.md,
        fontWeight: 'normal',
        color: black,
        padding: 0,
      },
      bannerContentSportsbookCarousel: {
        width: '100%',
        padding: [margins.xs, margins.md, margins.md],
        position: 'relative',
      },
    },
    BannerItemCasinoCarousel: {
      heightFix: {
        height: 320,
      },
      contentWrapper: {
        height: ' 100%',
        '&:before': {
          content: `''`,
          position: 'absolute',
          width: '40%',
          height: '100%',
          zIndex: ' 0',
          background: `linear-gradient(to right, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%)`,
        },
      },
    },
    BannerItemCasinoLiveCarousel: {
      heightFix: {
        height: 320,
      },
      contentWrapper: {
        height: ' 100%',
        '&:before': {
          content: `''`,
          position: 'absolute',
          width: '40%',
          height: '100%',
          zIndex: ' 0',
          background: `linear-gradient(to right, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%)`,
        },
      },
    },
    BannerItemSportsbookCarouselMobile: {
      imgContainer: {
        alignItems: 'flex-start',
        minHeight: 100,
      },
      header: {
        textShadow: `none`,
      },
      description: {
        textShadow: `none`,
        textAlign: 'left',
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: background2,
        borderBottom: `1px solid ${background1}`,
      },
      descriptionTimeWrapper: {
        color: black,
      },
      descriptionTeams: {
        color: black,
      },
    },
  };
};
