// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions60010: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { white, text2 },
    },
  } = themeVars;
  return {
    PromotionsComponent: {
      itemsByTypeTitle: {
        marginBottom: margins.md,
        fontSize: 30,
        textTransform: 'capitalize',
      },
      promoLink: {
        color: text2,
        '&:hover, &:active': {
          color: text2,
          borderBottom: `3px solid ${text2}`,
        },
      },
    },
    PromotionsWrapper: {
      promotionsRoot: {
        padding: [0, margins.md],
        '@media (pointer: coarse)': {
          padding: [margins.xm, 0],
        },
      },
      promotionsWrapper: {
        padding: [margins.lg, margins.md, margins.md],
        '@media (pointer: coarse)': {
          padding: margins.md,
        },
      },
      promotionsHeading: {
        fontSize: fontSizes.xxl,
      },
    },
    PromotionUI: {
      bannerContentHeader: {
        fontSize: fontSizes.xxxxl,
        padding: [margins.md, 0, 0],
        fontWeight: 'bold',
        color: white,
      },
      bannerContentTitle: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        color: white,
      },
      itemBody: {
        minHeight: 162,
        padding: '15px 15px 5px',
        height: '100%',
      },
      itemSecondTitle: {
        marginTop: margins.lg,
      },
      itemDescription: {
        padding: [margins.md, 0],
      },
      promoItemContainer: {
        marginBottom: margins.lg,
        '@media screen and (min-width: 1300px)': {
          flexBasis: 'calc((100% - 15px) / 2)',
        },
      },
    },
  };
};
