// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu60010: JSSThemeDefaultHeaderMenuType = (
  themeVars
) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { white },
      colorCombinations: { whitePrimary },
    },
  } = themeVars;

  return {
    HeaderMenuDesktop: {
      menuWrapper: whitePrimary,
      menuItem: {
        padding: [0, margins.xm],
        opacity: 0.8,
        '&:hover, &:focus': {
          color: white,
          opacity: 1,
        },
        '@media screen and (max-width: 1100px)': {
          fontSize: 12,
        },
      },
      menuItemActive: {
        color: white,
        opacity: 1,
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
    },
    HeaderMenuMobile: {
      menuItem: {
        fontSize: fontSizes.xl,
      },
    },
    HeaderSubmenuDesktop: {
      link: {
        opacity: 0.8,
        '&:hover, &:focus': {
          color: white,
          opacity: 1,
        },
      },
    },
  };
};
